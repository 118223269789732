import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, output, SimpleChange, viewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Dropdown, DropdownModule } from 'primeng/dropdown';

import { DEFAULT_SELECT_CONFIG, SelectConfig, SelectItem, SelectLoadingConfig } from '../../models/select.model';

@Component({
	selector: 'sl-select',
	templateUrl: './select.component.html',
	styleUrl: './select.component.scss',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: SelectComponent,
		multi: true
	}],
	standalone: true,
	imports: [
		CommonModule,
		DropdownModule,
		FormsModule,
		MatProgressSpinnerModule,
		ReactiveFormsModule
	]
})
export class SelectComponent implements OnChanges, ControlValueAccessor {

	@Input() options: SelectItem[];
	@Input() placeholder: string;
	@Input() config: SelectConfig = DEFAULT_SELECT_CONFIG;
	@Input() loadingConfig: SelectLoadingConfig;

	readonly selectionChanged = output<SelectItem>();
	readonly loadMoreItems = output<void>();

	readonly select = viewChild.required<Dropdown>(Dropdown);

	searchControl = new FormControl<SelectItem>(null);

	isLoadingItems = true;
	onChangeFunction: (fn: any) => void;
	onTouchedFunction: () => void;

	ngOnChanges(changes: { config: SimpleChange; value: SimpleChange; options: SimpleChange }): void {
		const previousValue = changes.value?.previousValue as SelectItem;
		const currentValue = changes.value?.currentValue as SelectItem;

		if (changes.config) {
			this.config = { ...DEFAULT_SELECT_CONFIG, ...changes.config.currentValue as SelectConfig };
		}

		if (currentValue?.name !== previousValue?.name) {
			this.selectionChanged.emit(currentValue);
		}

		if (changes.options) {
			if (Array.isArray(changes.options.currentValue) && changes.options.currentValue.length > 0) {
				this.isLoadingItems = false;
			}
		}
	}

	onChange(event: { originalEvent: PointerEvent; value: SelectItem }): void {
		this.selectionChanged.emit(event.value);
		if (this.onChangeFunction) {
			this.onChangeFunction(event.value);
		}
	}

	onLoadMoreItems(): void {
		this.loadMoreItems.emit();
		this.isLoadingItems = true;
	}

	writeValue(item: SelectItem): void {
		this.selectionChanged.emit(item);
		this.setItemInList(item);
	}

	setItemInList(item: SelectItem): void {
		const itemInList = this.options?.find(x => x.name === item?.name);
		if (itemInList) {
			this.searchControl.setValue(itemInList);

		} else {
			this.searchControl.setValue(item);
		}
	}

	registerOnChange(fn: () => void): void {
		this.onChangeFunction = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouchedFunction = fn;
		this.select().registerOnTouched(this.onTouchedFunction);
	}
}
