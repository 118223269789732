<p-dropdown
	styleClass="sl-v1-dropdown"
	panelStyleClass="sl-v1-dropdown-panel"
	[scrollHeight]="config.scrollHeight"
	[optionLabel]="config.optionLabel"
	optionDisabled="disabled"
	[placeholder]="config.placeholder"
	[appendTo]="config.appendTo"
	[options]="options"
	[filter]="config.showFilter"
	[filterBy]="config.optionLabel"
	[formControl]="searchControl"
	(onChange)="onChange($event)">
	<ng-template let-item pTemplate="item">
		<ng-container *ngIf="config.itemTemplate">
			<ng-container [ngTemplateOutlet]="config.itemTemplate" [ngTemplateOutletContext]="{ item }"></ng-container>
		</ng-container>
		<ng-container *ngIf="!config.itemTemplate">
			<div [title]="item[config.optionLabel]">{{ item[config.optionLabel] }}</div>
		</ng-container>
	</ng-template>
	<ng-template let-item pTemplate="selectedItem">
		<ng-container *ngIf="config?.itemTemplate">
			<ng-container [ngTemplateOutlet]="config?.itemTemplate" [ngTemplateOutletContext]="{ item }"></ng-container>
		</ng-container>
		<ng-container *ngIf="!config?.itemTemplate">
			<div [title]="item?.[config?.optionLabel]">{{ item?.[config?.optionLabel] }}</div>
		</ng-container>
	</ng-template>
	<ng-template pTemplate="footer">
		<div *ngIf="loadingConfig" class="dropdown-footer sl-flexbox-row sl-flexbox-align-center sl-flexbox-justify-start">
			<div [class.hidden]="isLoadingItems" class="sl-typography-link-action load-next" (click)="onLoadMoreItems()">Load next {{ loadingConfig.nextPageSize }} out of {{ loadingConfig.total - options.length }}...</div>
			<div [class.hidden]="!isLoadingItems" class="sl-flexbox-row sl-flexbox-align-center sl-flexbox-justify-center sl-flexbox-flex-dynamic">
				<mat-spinner [diameter]="24"></mat-spinner>
				<span class="sl-typography-body">Loading items...</span>
			</div>
		</div>
	</ng-template>
</p-dropdown>
